import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {Action, Box, BoxProps, Button, ButtonLink, ButtonLinkStyle, ButtonSize, ButtonStyle, ContentBlock, FlexContent, FlexContentDirection, FlexContentLayout, FlexContentMobileDirection, FlexContentSpacing, IconTooltip, LayoutRows, Loadable, Paragraph} from "@sirdata/ui-lib";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {ChartContainer, ChartLine} from "../component/widget";
import {CHART_DATASET_CONSENT_STYLES, ratingOptionsUnstacked} from "../utils/stat/chart_options";
import {CmpConfig} from "../api/model/cmp/config/CmpConfig";
import {CmpConfigStatus} from "../api/model/cmp/config/CmpConfigStatus";
import {CmpVersion} from "../api/model/cmp/version/CmpVersion";
import {ErrorResponse} from "../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../common/api/http/HttpStatusCode";
import {Locale} from "../common/utils/Locale";
import {MainHeader, NotificationCarousel} from "../common/component/snippet";
import {MainContent, Wrapper} from "../common/component/widget";
import {News} from "../common/api/model/portal/News";
import {pathAnalyticsConsent, pathAnalyticsConsentWithId, pathCmp, TranslationPortalFile} from "../utils/constants";
import {session} from "../api/ApiSession";
import {Formatter} from "../common/utils/Formatter";
import {StatPeriod} from "../utils/stat/StatPeriod";
import {StatHomeBoard} from "../utils/stat/consent/StatHomeBoard";
import {UIEventManager} from "../common/utils/UIEventManager";
import {ModalSupportUiEvent} from "../component/modal/ModalSupport";
import CmpConfigStepper from "../utils/CmpConfigStepper";
import {PathHelper} from "../common/utils/PathHelper";

const ANALYTICS_BOARDS_COUNT = 3;
const LATEST_NEWSES_COUNT = 5;

function Home() {
    const navigate = useNavigate();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textHome} = useTranslation(TranslationPortalFile.HOME);
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);
    const [isLoadingAnalytics, setLoadingAnalytics] = useState(true);

    const [analyticsCmpConfigs, setAnalyticsCmpConfigs] = useState<CmpConfig[]>([]);
    const [cmpVersions, setCmpVersions] = useState<CmpVersion[]>([]);
    const [latestNewses, setLatestNewses] = useState<News[]>([]);
    const [pendingCmpConfigs, setPendingCmpConfigs] = useState<CmpConfig[]>([]);

    const [activeAnalyticsBoardIndex, setActiveAnalyticsBoardIndex] = useState<number>(0);
    const [analyticsBoards, setAnalyticsBoards] = useState<Map<string, StatHomeBoard>>(new Map<string, StatHomeBoard>());

    const handleOpenSupport = () => {
        UIEventManager.emit(ModalSupportUiEvent);
    };

    useEffect(() => {
        if (!analyticsCmpConfigs.length) {
            return;
        }

        (async () => {
            try {
                setLoadingAnalytics(true);
                const interval = StatPeriod.LAST_30_DAYS.interval;
                const analyticsStatsGlobal = await session.restPartner.getConsentStatsGlobal(StatPeriod.LAST_30_DAYS, interval.start, interval.end);

                const newAnalyticsBoards: Map<string, StatHomeBoard> = new Map<string, StatHomeBoard>();
                const selectedCmpConfigs = analyticsCmpConfigs.filter((it) => Object.keys(analyticsStatsGlobal).includes(it.id));
                selectedCmpConfigs.forEach((config) => {
                    const configStats = analyticsStatsGlobal[config.id];
                    const configBoard = new StatHomeBoard(configStats.dates);
                    configBoard.consent = configStats.ratings.consent;
                    configBoard.addDataset({
                        label: textAnalytics("metric.consent"),
                        data: configStats.daily.consent,
                        ...CHART_DATASET_CONSENT_STYLES,
                        pointRadius: 2
                    });
                    newAnalyticsBoards.set(config.id, configBoard);
                });
                setAnalyticsBoards(newAnalyticsBoards);
            } catch (e) {
                setAnalyticsBoards(new Map<string, StatHomeBoard>());
                if (e instanceof ErrorResponse) {
                    console.error("Failed to load analytics", e.message);
                }
            } finally {
                setLoadingAnalytics(false);
            }
        })();
    }, [analyticsCmpConfigs, textAnalytics]);

    const loadPartnerData = () => {
        (async () => {
            try {
                const cmpConfigs = await session.restCmpConfig.list();
                setAnalyticsCmpConfigs(cmpConfigs.filter((it) => it.status === CmpConfigStatus.ACTIVE).slice(0, ANALYTICS_BOARDS_COUNT));

                const pendingCmpConfigs = cmpConfigs.filter(({status, step}) => status === CmpConfigStatus.INACTIVE && !!step);
                setPendingCmpConfigs(pendingCmpConfigs);
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                    console.error("Failed to load partner data", e.message);
                }
            }
        })();
    };

    useEffect(() => {
        (async () => {
            try {
                await session.loadAccount();

                const cmpVersions = await session.restPortalCmp.getVersions();
                setCmpVersions(cmpVersions);

                const latestNewses = await session.getNewses();
                setLatestNewses(latestNewses.filter(({language}) => Locale.getCurrentLocale() === language).slice(0, LATEST_NEWSES_COUNT));

                loadPartnerData();
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    console.error("Failed to load data", e.message);
                }
            }
        })();
    }, []);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <LayoutRows cssClass="home">
                    <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.LARGE_PLUS} layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT} mobileDirection={FlexContentMobileDirection.COLUMN}>
                        <LayoutRows>
                            <NotificationCarousel cssClass="home__notification-carousel"/>
                            {analyticsCmpConfigs.length > 0 ?
                                <ContentBlock
                                    header={{title: {label: textHome("consent_rates").toUpperCase(), icon: {name: "bar_chart"}}}}
                                    footer={{link: pathAnalyticsConsent, label: textHome("see_all_sites")}}
                                >
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW} cssClass={"consent-rates"}>
                                        <div className="consent-sites">
                                            <div className="site-items">
                                                {analyticsCmpConfigs.map((config, index) =>
                                                    <div className={clsx("site-item", {"active": activeAnalyticsBoardIndex === index})} key={config.id} onClick={() => setActiveAnalyticsBoardIndex(index)}>
                                                        <div className="site-infos">
                                                            <div className="site-name">
                                                                <span onClick={() => navigate(PathHelper.buildPathWithId(pathAnalyticsConsentWithId, config.id))}>{config.name}</span>
                                                            </div>
                                                            <Loadable loading={isLoadingAnalytics} loaderOptions={{small: true}}>
                                                                <div className="site-rate">
                                                                    {`${analyticsBoards.get(config.id)?.consent || 0}%`}
                                                                </div>
                                                            </Loadable>
                                                        </div>
                                                        <div className="site-arrow"/>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="consent-charts">
                                            <span className="chart-title">{textHome("consent_rates_chart_title")}</span>
                                            {analyticsCmpConfigs.map((config, index) =>
                                                analyticsBoards &&
                                                <ChartContainer cssClass={clsx("chart-item", {"active": activeAnalyticsBoardIndex === index})} key={config.id}>
                                                    <ChartLine data={analyticsBoards.get(config.id)?.data} options={ratingOptionsUnstacked}/>
                                                </ChartContainer>
                                            )}
                                        </div>
                                    </Box>
                                </ContentBlock> :
                                <ContentBlock header={{title: {label: textHome("consent_rates").toUpperCase(), icon: {name: "bar_chart"}}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <span>{textHome("data_not_available")}</span>
                                    </Box>
                                </ContentBlock>
                            }
                            {cmpVersions.length > 0 &&
                                <ContentBlock header={{title: {label: textHome("changelog").toUpperCase(), icon: {name: "build"}}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW} cssClass={"changelog"}>
                                        {cmpVersions.map(({version, date, text_fr, text_en}) =>
                                            <div key={version} className="changelog-item">
                                                <div className="version-num">v{version}</div>
                                                <div className="version-text" dangerouslySetInnerHTML={{__html: Locale.isFrench() ? text_fr : text_en}}/>
                                                <div className="version-date">{date}</div>
                                            </div>
                                        )}
                                    </Box>
                                </ContentBlock>
                            }
                        </LayoutRows>
                        <LayoutRows>
                            {latestNewses.length > 0 &&
                                <ContentBlock
                                    header={{title: {label: textHome("newses").toUpperCase(), icon: {name: "article"}}}}
                                    footer={{link: "https://news.sirdata.com/tag/cmp/", label: textHome("see_all_newses"), target: "_blank"}}
                                >
                                    <Box cssClass={"home__news"} {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        {latestNewses.map(({title, url, pubDate}) =>
                                            <div key={title} className="news__item">
                                                <span className="news__item__date">{Formatter.formatDate(pubDate)}</span>
                                                <ButtonLink
                                                    cssClass="news__item__title"
                                                    style={ButtonLinkStyle.MIDNIGHT_LIGHT}
                                                    onClick={() => window.open(url, "_blank")}
                                                    reverseUnderline
                                                >
                                                    {title}
                                                </ButtonLink>
                                            </div>
                                        )}
                                    </Box>
                                </ContentBlock>
                            }
                            {pendingCmpConfigs.length > 0 &&
                                <ContentBlock
                                    header={{title: {label: textHome("current_cmp").toUpperCase(), icon: {name: "priority_high", cssClass: "home__icon-priority"}}}}
                                    footer={{link: pathCmp, label: textHome("see_all_sites")}}
                                >
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW} cssClass={"home__inprogress"}>
                                        {pendingCmpConfigs.map((cmpConfig, index) => {
                                            const nextStep = cmpConfig.getNextStep();
                                            if (!nextStep) return <></>;
                                            return (
                                                <div key={`pending-cmp-config-${index.toString()}`} className="inprogress__item">
                                                    <div className="inprogress__item__infos">
                                                        <span>{cmpConfig.name}</span>
                                                        <IconTooltip
                                                            icon={Action.EDIT.icon}
                                                            text={t("list.action.resume")}
                                                            cssClass="inprogress__item__infos__icon-button"
                                                            onClick={() => navigate(nextStep.getPath(cmpConfig.id))}
                                                        />
                                                    </div>
                                                    <div className="inprogress__item__progress">
                                                        <div className="inprogress__item__progress__bars">
                                                            <span className="inprogress__item__progress__bars__back"/>
                                                            <span className="inprogress__item__progress__bars__track" style={{width: (CmpConfigStepper.getStepPosition(cmpConfig.step)) * (100 / CmpConfigStepper.steps.length) + "%"}}/>
                                                        </div>
                                                        <span className="inprogress__item__progress__state"><span>{CmpConfigStepper.getStepPosition(cmpConfig.step)}</span>/{CmpConfigStepper.steps.length} {t("misc.completed")}</span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </Box>
                                </ContentBlock>
                            }
                            <ContentBlock header={{title: {label: textHome("support").toUpperCase(), icon: {name: "support"}}}}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM} cssClass={"home__support"}>
                                        <div className="support__picture">
                                            <img src="/common/images/support/superdata.png" alt="Superdata"/>
                                        </div>
                                        <span className="h3">{textHome("support_title")}</span>
                                        <Paragraph>{textHome("support_text")}</Paragraph>
                                        <Button size={ButtonSize.MEDIUM} style={ButtonStyle.PRIMARY_GREEN} onClick={handleOpenSupport} icon={{name: "chat_bubble", outlined: true}}>
                                            {textHome("support_btn")}
                                        </Button>
                                    </FlexContent>
                                </Box>
                            </ContentBlock>
                        </LayoutRows>
                    </FlexContent>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default Home;
