import {MailRequest} from "../../../common/api/model/mailing/MailRequest";
import {CmpConfig} from "../cmp/config/CmpConfig";

enum Template {
    TOOLBAR_ACTIVE = "cmp-integration-toolbar-active",
    TOOLBAR_INACTIVE = "cmp-integration-toolbar-inactive",
    VIA_GTM = "cmp-integration-gtm"
}

export class IntegrationMailRequest extends MailRequest {
    template: Template = Template.TOOLBAR_ACTIVE;

    constructor(cmpConfig: CmpConfig) {
        super();
        this.template = cmpConfig.settings.theme.toolbar.active ? Template.TOOLBAR_ACTIVE : Template.TOOLBAR_INACTIVE;
        this.values = {
            "partner_id": cmpConfig.id_partner.toString(),
            "config_id": cmpConfig.id,
            "site_name": cmpConfig.name
        };
    }

    setViaGTM() {
        this.template = Template.VIA_GTM;
    }
}
