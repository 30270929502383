import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Action,
    Alert,
    AlertSeverity,
    Box,
    BoxRadius,
    BoxShadow,
    BoxSpacing,
    Button,
    ButtonLink,
    ButtonSize,
    ButtonStyle,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentMobileDirection,
    FlexContentProps,
    FlexContentSpacing,
    HeroHeader,
    Icon,
    LayoutRows,
    Loadable,
    MainContentStyle,
    Paragraph,
    ParagraphAlignment,
    ToggleSwitch
} from "@sirdata/ui-lib";

import ModalConfirmAccess from "../component/modal/ModalConfirmAccess";
import {ActivationStatus} from "../common/utils/ActivationStatus";
import {DEFAULT_PATH, pathBarterAgreement, pathIndex, pathTermsOfSale, TranslationPortalFile} from "../utils/constants";
import {MainContent, Wrapper} from "../common/component/widget";
import {MainHeader} from "../common/component/snippet";
import {Origin} from "../common/api/model/Origin";
import {PORTAL} from "../utils/Portal";
import {session} from "../api/ApiSession";
import {sirdataCmpPricingUrl} from "../utils/helper";
import {pathAccess, sirdataDataPrivacyPolicyUrl} from "../common/utils/constants";
import {usePreprod} from "../common/utils/helper";

type AccessForm = {
    termsOfUse: boolean;
    barterAgreement: boolean;
    privacyPolicy: boolean;
}

function Access() {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textAccess} = useTranslation(TranslationPortalFile.ACCESS);
    const location = useLocation();
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);
    const [accessForm, setAccessForm] = useState<AccessForm>({termsOfUse: false, barterAgreement: false, privacyPolicy: false});
    const [isPremium, setPremium] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const account = await session.getAccount();
                if (!location.search && account.hasAccess(PORTAL.origin.name)) {
                    navigate(DEFAULT_PATH);
                }
            } catch {
                navigate(pathIndex);
            } finally {
                setLoading(false);
            }
        })();
    }, [navigate, location.search]);

    const isFormValidated = () => {
        const commonRequisites = accessForm.termsOfUse && accessForm.privacyPolicy;

        if (!isPremium) {
            return commonRequisites && accessForm.barterAgreement;
        }

        return commonRequisites;

    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.FULL_WIDTH} cssClass="access">
                <HeroHeader title={textAccess("title")}/>
                <LayoutRows cssClass={"activation-section"}>
                    <FlexContent {...FlexContentProps.LAYOUT_COLUMNS} alignment={FlexContentAlignment.CENTER} mobileDirection={FlexContentMobileDirection.COLUMN_REVERSE}>
                        <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER} cssClass="pricing">
                            <Box spacing={BoxSpacing.LARGE} cssClass="pricing__item">
                                {isPremium ? (
                                    <>
                                        <div className="pricing__item__tax">
                                            <span>{t("pricing.premium.price_before")}</span>
                                            <span className="pricing__item__tax__price">{t("pricing.premium.price")}</span>
                                            <span>{t("pricing.premium.price_after")}</span>
                                        </div>
                                        <Alert text={t("pricing.free_trial")}/>
                                        <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                                            {(t("pricing.premium.details", {returnObjects: true}) as string[]).map((it) =>
                                                <Paragraph key={it} alignment={ParagraphAlignment.JUSTIFY} cssClass="pricing__item__details">
                                                    <Icon name="east"/>
                                                    {it}
                                                </Paragraph>
                                            )}
                                        </FlexContent>
                                    </>
                                ) : (
                                    <>
                                        <div className="pricing__item__tax">
                                            <span className="pricing__item__tax__price">{t("pricing.freemium.price")}</span>
                                        </div>
                                        <Alert text={t("pricing.freemium.alert")}/>
                                        <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                                            {(t("pricing.freemium.details", {returnObjects: true}) as string[]).map((it) =>
                                                <Paragraph key={it} alignment={ParagraphAlignment.JUSTIFY} cssClass="pricing__item__details">
                                                    <Icon name="east"/>
                                                    {it}
                                                </Paragraph>
                                            )}
                                        </FlexContent>
                                    </>
                                )}
                            </Box>
                            <ButtonLink
                                cssClass="login-right__content__see-more"
                                icon={Action.OPEN.icon}
                                reverseUnderline
                                onClick={() => window.open(sirdataCmpPricingUrl, "_blank")}
                            >
                                {t("pricing.action.see_more")}
                            </ButtonLink>
                        </FlexContent>
                        <Loadable loading={isLoading}>
                            <Box radius={BoxRadius.MD} shadow={BoxShadow.BLACK_BIG} cssClass="activation-card">
                                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.LARGE}>
                                    {location.search.includes(ActivationStatus.ERROR) &&
                                        <Alert
                                            text={textAccess("error.activation")}
                                            severity={AlertSeverity.DANGER}
                                            onClose={() => navigate(pathAccess, {replace: true})}
                                            fullWidth
                                        />
                                    }
                                    <span className="h1 activation-card__title" dangerouslySetInnerHTML={{__html: textAccess("text")}}/>
                                    <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.START}>
                                        <ToggleSwitch
                                            checked={accessForm.termsOfUse}
                                            onChange={(value) => setAccessForm((prevState) => ({...prevState, termsOfUse: value}))}
                                        />
                                        <span className="activation-card__terms" dangerouslySetInnerHTML={{__html: textAccess("items.terms_of_use", {termsOfUseUrl: pathTermsOfSale})}}/>
                                    </FlexContent>
                                    <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.START}>
                                        <ToggleSwitch
                                            checked={accessForm.privacyPolicy}
                                            onChange={(value) => setAccessForm((prevState) => ({...prevState, privacyPolicy: value}))}
                                        />
                                        <span className="activation-card__terms" dangerouslySetInnerHTML={{__html: textAccess("items.privacy_policy", {privacyPolicyUrl: sirdataDataPrivacyPolicyUrl})}}/>
                                    </FlexContent>
                                    {!isPremium &&
                                        <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.START}>
                                            <ToggleSwitch
                                                checked={accessForm.barterAgreement}
                                                onChange={(value) => setAccessForm((prevState) => ({...prevState, barterAgreement: value}))}
                                            />
                                            <span className="activation-card__terms" dangerouslySetInnerHTML={{__html: textAccess("items.barter_agreement", {barterAgreementUrl: pathBarterAgreement})}}/>
                                        </FlexContent>
                                    }
                                    <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER}>
                                        <Button
                                            size={ButtonSize.BIG}
                                            style={ButtonStyle.PRIMARY_GREEN}
                                            onClick={() => window.location.href = Origin.ACCOUNT.getUrl(usePreprod) + `/activate-service?origin=${PORTAL.origin.name}${isPremium ? "&premium=1" : ""}`}
                                            disabled={!isFormValidated()}
                                        >
                                            {textAccess("activate_product")}
                                        </Button>
                                    </FlexContent>
                                </FlexContent>
                            </Box>
                        </Loadable>
                    </FlexContent>
                    <Button
                        size={ButtonSize.SMALL}
                        style={ButtonStyle.DEFAULT_GREEN}
                        icon={{name: "east"}}
                        onClick={() => setPremium((prev) => !prev)}
                        cssClass="access__bottom-cta"
                        reverse
                    >
                        {t(`pricing.action.register_freemium${isPremium ? "" : "_not"}`)}
                    </Button>
                </LayoutRows>
                <ModalConfirmAccess active={location.search.includes(ActivationStatus.SUCCESS)} onStart={() => navigate(PORTAL.defaultPath)}/>
            </MainContent>
        </Wrapper>
    );
}

export default Access;
