import {EmailInfo} from "../account/EmailInfo";
import {ApiModel} from "../ApiModel";

export class MailRequest extends ApiModel {
    template: string = "";
    email_info: EmailInfo = new EmailInfo();
    cc_emails: string[] = [];
    values: { [key: string]: string } = {};

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            template: this.template,
            email_info: this.email_info,
            cc_emails: this.cc_emails.filter((it) => !!it),
            values: this.values
        };
    }
}
