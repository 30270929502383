import {CmpConfigAddOnsGroup} from "./CmpConfigAddOnsGroup";
import {CmpConfigExternalField} from "../api/model/cmp/config/CmpConfigExternalField";
import {googleAdManagerDocUrl, googleAnalyticsCnilWarningUrl, googleConsentModeUrl, microsoftConsentModeUrl} from "./helper";

export class CmpConfigAddOn {
    field: CmpConfigExternalField;
    labelKey: string = "";
    logo: string = "";
    textOptions: any;
    productLogos?: string[];

    static GOOGLE_CONSENT_MODE: CmpConfigAddOn = new CmpConfigAddOn(CmpConfigExternalField.GOOGLE_ANALYTICS, "google_consent_mode", "google.png", {returnObjects: true, googleConsentModeUrl: googleConsentModeUrl, googleAnalyticsCnilWarningUrl: googleAnalyticsCnilWarningUrl}).withProductLogos(["google-analytics.png", "google-ads.png", "google-tag-manager.png", "campaign-manager.png", "google-DV360.png"]);
    static GOOGLE_ADSENSE: CmpConfigAddOn = new CmpConfigAddOn(CmpConfigExternalField.GOOGLE_AD_SENSE, "google_adsense", "adsense.png", {returnObjects: true});
    static GOOGLE_AD_MANAGER: CmpConfigAddOn = new CmpConfigAddOn(CmpConfigExternalField.GOOGLE_AD_MANAGER, "google_ad_manager", "ad-manager.png", {returnObjects: true, googleAdManagerDocUrl: googleAdManagerDocUrl});
    static SHOPIFY: CmpConfigAddOn = new CmpConfigAddOn(CmpConfigExternalField.SHOPIFY, "shopify", "shopify.png", {returnObjects: true});
    static MICROSOFT: CmpConfigAddOn = new CmpConfigAddOn(CmpConfigExternalField.MICROSOFT_UET, "microsoft", "microsoft.png", {returnObjects: true, microsoftConsentModeUrl: microsoftConsentModeUrl});
    static CONSENT_MODE_FROM_TCF: CmpConfigAddOn = new CmpConfigAddOn(CmpConfigExternalField.CONSENT_MODE_FROM_TCF, "consent_mode_from_tcf", "google-ads.png", {returnObjects: true});
    static UTIQ: CmpConfigAddOn = new CmpConfigAddOn(CmpConfigExternalField.UTIQ, "utiq", "utiq.png", {returnObjects: true});

    constructor(field: CmpConfigExternalField, labelKey: string, logo: string, textOptions: any) {
        this.field = field;
        this.labelKey = labelKey;
        this.logo = logo;
        this.textOptions = textOptions;
    }

    isShowIndication(): boolean {
        return CmpConfigAddOnsGroup.GOOGLE.addOns.includes(this);
    }

    isCustomActivation(): boolean {
        return this === CmpConfigAddOn.UTIQ;
    }

    withProductLogos(productLogos: string[]): CmpConfigAddOn {
        this.productLogos = productLogos;
        return this;
    }
}
