import {FC, FormEvent, useEffect, useState} from "react";
import {Action, AlertSeverity, Button, ButtonLink, ButtonLinkCancel, ButtonSize, FieldBlock, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, Form, FormLayoutButtons, FormLayoutRows, FormValidationType, IconTooltip, InputEmail, ModalContent, ModalNew, ModalTitle, TranslationLibFile} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../utils/constants";
import {useTranslation} from "react-i18next";
import {IntegrationMailRequest} from "../../api/model/mailing/IntegrationMailRequest";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigIntegrationType} from "../../utils/CmpConfigIntegrationType";
import {session} from "../../api/ApiSession";
import {UIEventManager} from "../../common/utils/UIEventManager";

type ModalSendMailIntegrationProps = {
    cmpConfig: CmpConfig;
    integrationType: CmpConfigIntegrationType | undefined;
    onClose: () => void;
};

type CCEmail = {
    id: number;
    email: string;
};

const ModalSendMailIntegration: FC<ModalSendMailIntegrationProps> = ({cmpConfig, integrationType, onClose}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textConfigIntegration} = useTranslation(TranslationPortalFile.CONFIG_INTEGRATION);
    const [ccEmails, setCCEmails] = useState<CCEmail[]>([]);
    const [partnerEmail, setPartnerEmail] = useState("");
    const FORM_ID = "form-send-mail-integration";

    useEffect(() => {
        if (!integrationType) return;
        setCCEmails([]);
        (async () => {
            try {
                const loggedAccount = await session.getAccount();
                setPartnerEmail(loggedAccount.email);
            } catch (e) {
            }
        })();
    }, [integrationType]);

    const handleAddEmail = () => {
        setCCEmails((prevState) => ([...prevState, {id: ccEmails.length + 1, email: ""}]));
    };

    const handleRemoveEmail = (id: number) => {
        setCCEmails((prevState) => prevState.filter((it) => it.id !== id));
    };

    const handleChange = (id: number, value: string) => {
        setCCEmails((prevState) => prevState.map((it) => it.id === id ? {id: id, email: value} : it));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const request = new IntegrationMailRequest(cmpConfig);
            if (integrationType === CmpConfigIntegrationType.GTM) {
                request.setViaGTM();
            }
            request.cc_emails = ccEmails.map((it) => it.email);

            await session.restMailing.sendMail(request);
            UIEventManager.alert(textConfigIntegration("send_by_email.success"), AlertSeverity.SUCCESS);
        } catch (e) {
            UIEventManager.alert(textConfigIntegration("send_by_email.error"), AlertSeverity.DANGER);
        } finally {
            onClose();
        }
    };

    return (
        <ModalNew onClose={onClose} active={!!integrationType}>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <ModalTitle>{textConfigIntegration("send_by_email.recipients")}</ModalTitle>
                    <FormLayoutRows>
                        <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                            <FieldBlock label={textConfigIntegration("send_by_email.email")}>
                                <InputEmail value={partnerEmail} onChange={() => {}} disabled/>
                            </FieldBlock>
                            {ccEmails.map(({id, email}) =>
                                <FieldBlock key={id} label={textConfigIntegration("send_by_email.email")}>
                                    <InputEmail value={email} onChange={(value) => handleChange(id, value)}/>
                                    <IconTooltip
                                        icon={Action.REMOVE.icon}
                                        text={textCommon(Action.REMOVE.labelKey)}
                                        onClick={() => handleRemoveEmail(id)}
                                    />
                                </FieldBlock>
                            )}
                            <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.START}>
                                <ButtonLink reverse icon={{name: "add_circle"}} onClick={handleAddEmail}>
                                    {textConfigIntegration("send_by_email.add_recipient")}
                                </ButtonLink>
                            </FlexContent>
                        </FormLayoutRows>
                        <FormLayoutButtons>
                            <ButtonLinkCancel onClick={onClose}/>
                            <Button type="submit" form={FORM_ID} size={ButtonSize.BIG}>{textConfigIntegration("send_by_email.send")}</Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalSendMailIntegration;
