import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {
    AlertSeverity,
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    FlexContentDirection,
    FormLayoutButtons,
    FormLayoutColumns,
    FormLayoutMention,
    FormLayoutRows,
    FormLayoutTitle,
    InputDomain,
    InputText,
    ModalContent,
    ModalNew
} from "@sirdata/ui-lib";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigField} from "../../api/model/cmp/config/CmpConfigField";
import {CmpConfigStepName} from "../../utils/CmpConfigStepper";
import {CmpConfigSettings} from "../../api/model/cmp/config/CmpConfigSettings";
import {CmpConfigSettingsField} from "../../api/model/cmp/config/CmpConfigSettingsField";
import {session} from "../../api/ApiSession";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {validateUrl} from "../../common/utils/string";
import {CmpConfigPropertiesCreationType} from "../../api/model/cmp/config/CmpConfigPropertiesCreationType";
import {FormLayoutMessage} from "../../common/component/snippet";

export type EventConfigInfoArgs = {
    cmpConfig: CmpConfig;
    onClose?: () => void;
}

type ConfigInfoFormErrors = {
    domain?: boolean;
    privacyPolicy?: boolean;
}

const ModalConfigAdvancedInfo: FunctionComponent = () => {
    const navigate = useNavigate();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);

    const [cmpConfig, setCmpConfig] = useState<CmpConfig>(new CmpConfig());
    const [formErrors, setFormErrors] = useState<ConfigInfoFormErrors>();

    const [isActive, setActive] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [onClose, setOnClose] = useState<() => void>(() => {});

    useEffect(() => {
        UIEventManager.addListener(ModalConfigAdvancedInfoUiEvent, (args: EventConfigInfoArgs) => {
            const {cmpConfig, onClose} = args;
            setCmpConfig(cmpConfig);
            setOnClose(() => onClose);
            setActive(true);
        });

        return () => {
            UIEventManager.removeAllListeners(ModalConfigAdvancedInfoUiEvent);
        };
    }, [cmpConfig]);

    const handleChange = (field: CmpConfigField, value: any) => {
        setCmpConfig((prevState) => new CmpConfig({...prevState, [field]: value}));
    };

    const handleChangeSettings = (field: CmpConfigSettingsField, value: any) => {
        handleChange(CmpConfigField.SETTINGS, new CmpConfigSettings({...cmpConfig.settings, [field]: value}));
    };

    const handleSubmit = async (e?: FormEvent) => {
        if (!cmpConfig) return;
        if (e) e.preventDefault();

        const newCmpConfig = new CmpConfig(cmpConfig);
        let formErrors: ConfigInfoFormErrors = {domain: false, privacyPolicy: false};

        newCmpConfig.domain = new URL(newCmpConfig.domain.includes("http") ? newCmpConfig.domain : `https://${newCmpConfig.domain}`).hostname;
        formErrors.domain = !validateUrl(newCmpConfig.domain);

        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        if (!newCmpConfig.settings.privacyPolicy.includes("http")) {
            newCmpConfig.settings.privacyPolicy = `https://${newCmpConfig.settings.privacyPolicy}`;
        }
        formErrors.privacyPolicy = !validateUrl(newCmpConfig.settings.privacyPolicy);

        if (formErrors.domain || formErrors.privacyPolicy) {
            setFormErrors(formErrors);
            return;
        }

        setLoading(true);
        try {
            if (newCmpConfig.id) {
                await session.restCmpConfig.update(newCmpConfig);
                setLoading(false);
                handleClose();
            } else {
                newCmpConfig.properties.creation_type = CmpConfigPropertiesCreationType.ADVANCED;
                const createdCmpConfig = await session.restCmpConfig.create(newCmpConfig);
                navigate(createdCmpConfig.getPath(CmpConfigStepName.BANNER));
            }
        } catch (e) {
            UIEventManager.alert(textConfiguration("error.update_cmp"), AlertSeverity.DANGER);
            setLoading(false);
        }
    };

    const handleClose = () => {
        setFormErrors({});
        setCmpConfig(new CmpConfig());
        setActive(false);
        onClose && onClose();
    };

    const hasEmptyField = () => {
        if (!cmpConfig.name || !cmpConfig.domain) {
            return true;
        }

        return !cmpConfig.settings.privacyPolicy;
    };

    return (
        <ModalNew onClose={handleClose} active={isActive}>
            <ModalContent>
                <FormLayoutRows>
                    <FormLayoutTitle>{textConfiguration("modal_config.site_info")}</FormLayoutTitle>
                    <FormLayoutColumns>
                        <FieldBlock
                            label={textConfiguration(`field.${CmpConfigField.NAME}`)}
                            required
                        >
                            <InputText
                                placeholder={textConfiguration(`placeholder.${CmpConfigField.NAME}`)}
                                value={cmpConfig.name}
                                onChange={(value) => handleChange(CmpConfigField.NAME, value)}
                                autoFocus
                            />
                        </FieldBlock>
                        <FieldBlock
                            label={textConfiguration(`field.${CmpConfigField.DOMAIN}`)}
                            content={{direction: FlexContentDirection.COLUMN}}
                            required
                        >
                            <InputDomain
                                placeholder={textConfiguration(`placeholder.${CmpConfigField.DOMAIN}`)}
                                value={cmpConfig.domain}
                                onChange={(value) => handleChange(CmpConfigField.DOMAIN, value.toLowerCase())}
                            />
                            {!!formErrors?.domain &&
                                <FormLayoutMessage message={t("error.invalid_domain")} severity={AlertSeverity.DANGER} small/>
                            }
                        </FieldBlock>
                    </FormLayoutColumns>
                    <FieldBlock
                        label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PRIVACY_POLICY}`)}
                        content={{direction: FlexContentDirection.COLUMN}}
                        required
                    >
                        <InputText
                            value={cmpConfig.settings.privacyPolicy}
                            placeholder={textConfiguration(`placeholder.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PRIVACY_POLICY}`)}
                            onChange={(value) => handleChangeSettings(CmpConfigSettingsField.PRIVACY_POLICY, value.toLowerCase())}
                        />
                        {!!formErrors?.privacyPolicy &&
                            <FormLayoutMessage message={t("error.invalid_url")} severity={AlertSeverity.DANGER} small/>
                        }
                    </FieldBlock>
                    <FormLayoutMention/>
                    <FormLayoutButtons>
                        <ButtonLinkCancel onClick={handleClose}/>
                        <ButtonValidate onClick={handleSubmit} disabled={hasEmptyField()} loading={isLoading}/>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalConfigAdvancedInfo;
export const ModalConfigAdvancedInfoUiEvent = "ModalConfigAdvancedInfo";
