import {Rest} from "../../common/api/rest/Rest";
import {CmpVersion} from "../model/cmp/version/CmpVersion";
import {CmpWebinar} from "../model/cmp/webinar/CmpWebinar";

export class RestPortalCmp extends Rest {
    rootPath = "/cmp-api/portal";

    async getVersions(): Promise<CmpVersion[]> {
        return this._client.get(`${this.rootPath}/version`, CmpVersion) as Promise<CmpVersion[]>;
    }

    async getWebinar(webinarId: number): Promise<CmpWebinar> {
        return this._client.get(`${this.rootPath}/webinar/${webinarId}`, CmpWebinar) as Promise<CmpWebinar>;
    }
}
