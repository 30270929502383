import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Locale} from "../../../../common/utils/Locale";
import {CmpWebinarInfo} from "./CmpWebinarInfo";
import {CmpWebinarFaqItem} from "./CmpWebinarFaqInfo";

export class CmpWebinar extends ApiModel {
    id: number = 0;
    date: string = "";
    _info: Map<Locale, CmpWebinarInfo> | undefined;
    _faq: Map<Locale, CmpWebinarFaqItem[]> | undefined;
    status: string = "";
    last_update: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get info(): Map<Locale, CmpWebinarInfo> | undefined {
        return this._info;
    }

    set info(info: Map<Locale, CmpWebinarInfo> | undefined) {
        if (!info) {
            return;
        }

        this._info = Object.entries(info).length ? new Map(Object.entries(info)) : info;
    }

    get faq(): Map<Locale, CmpWebinarFaqItem[]> | undefined {
        return this._faq;
    }

    set faq(faq: Map<Locale, CmpWebinarFaqItem[]> | undefined) {
        if (!faq) {
            return;
        }

        this._faq = Object.entries(faq).length ? new Map(Object.entries(faq)) : faq;
    }
}
