import {FunctionComponent, useEffect, useState} from "react";
import {ContentBlock, FlexContent, FlexContentDirection, FlexContentJustify, FlexContentSpacing, IconTooltip, InputRadio, Loadable, ModalContent, ModalNew, ModalNewSize, ModalTitle, Paragraph, ParagraphAlignment} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {Preview} from "../index";
import {CMP_CONFIG_DISPLAY_MODES, CmpConfigVendorListDisplayMode} from "../../../api/model/cmp/config/CmpConfigVendorListDisplayMode";
import {Partner} from "../../../utils/Partner";
import {PartnerType} from "../../../utils/PartnerType";
import {sortItems} from "../../../common/utils/helper";
import {VendorField} from "../../../api/model/cmp/list/global-vendor-list/VendorField";
import {CmpConfigVendorList} from "../../../api/model/cmp/config/CmpConfigVendorList";
import {Network} from "../../../api/model/cmp/list/network-list/Network";
import {CmpConfigVendorListField} from "../../../api/model/cmp/config/CmpConfigVendorListField";

type PurposesDisplayProps = {
    cmpConfig: CmpConfig;
    onChange: (displayMode: CmpConfigVendorListDisplayMode) => void;
}

const PurposesDisplay: FunctionComponent<PurposesDisplayProps> = ({cmpConfig, onChange}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textConfigPurposes} = useTranslation(TranslationPortalFile.CONFIG_PURPOSES);
    const [previewConfig, setPreviewConfig] = useState<CmpConfig>(new CmpConfig());
    const [activePreviewDisplayMode, setActivePreviewDisplayMode] = useState<CmpConfigVendorListDisplayMode>();
    const [isLoadingPreview, setLoadingPreview] = useState(false);

    const handlePreview = (displayMode: CmpConfigVendorListDisplayMode) => {
        setPreviewConfig((prevState) => {
            const newPreviewConfig = new CmpConfig(prevState);
            newPreviewConfig.settings.vendorList.displayMode = displayMode;
            return newPreviewConfig;
        });

        setActivePreviewDisplayMode(displayMode);
        setLoadingPreview(true);
        setTimeout(() => setLoadingPreview(false), 200);
    };

    useEffect(() => {
        (async () => {
            try {
                const globalVendorList = await session.restList.getGlobalVendorList();
                const sirdataList = await session.restList.getSirdataList();
                const googleACProviderList = await session.restList.getGoogleACProviderList();
                const networkList = await session.restList.getNetworkList();
                const isPremiumLicense = await session.isPremium();

                let partners: Partner[] = [];
                partners.push(...globalVendorList.vendors.filter((vendor) => !vendor.deletedDate).map((vendor) => new Partner(vendor, PartnerType.IAB)));
                partners.push(...googleACProviderList.providers.map((provider) => new Partner(provider, PartnerType.Google)));
                partners.push(...sirdataList.vendors.filter((sirdataVendor) => (isPremiumLicense || !sirdataVendor.premium) && !sirdataVendor.deletedDate).map((sirdataVendor) => new Partner(sirdataVendor, PartnerType.Sirdata)));
                partners = sortItems(partners, VendorField.NAME);

                const selectedNetworkLists = cmpConfig.settings.vendorList.networks?.map((id) => networkList.getNetwork(id)).filter((it) => !!it) as Network[];
                const selectedNetworkPartners = partners.filter((partner) => {
                    return selectedNetworkLists.some((network) => {
                        return ((network.vendors.includes(partner.id) && partner.isIABVendor) ||
                            (network.providers.includes(partner.id) && partner.isGoogleProvider) ||
                            (network.sirdataVendors.includes(partner.id) && partner.isSirdataVendor));
                    });
                });

                // Mandatory partners for freemium license
                let freemiumLicensePartners: Partner[] = [];
                if (!isPremiumLicense) {
                    const freemiumList = await session.restList.getFreemiumList();
                    freemiumLicensePartners.push(...partners.filter((partner) => (partner.isIABVendor && freemiumList.vendors?.includes(partner.id)) || (partner.isSirdataVendor && freemiumList.sirdataVendors?.includes(partner.id))));
                }

                const selectedPartners = [
                    ...partners.filter((partner) => cmpConfig.settings.vendorList.vendors?.includes(partner.id) && partner.isIABVendor),
                    ...partners.filter((partner) => cmpConfig.settings.vendorList.googleProviders?.includes(partner.id) && partner.isGoogleProvider),
                    ...partners.filter((partner) => cmpConfig.settings.vendorList.sirdataVendors?.includes(partner.id) && partner.isSirdataVendor),
                    ...selectedNetworkPartners,
                    ...freemiumLicensePartners
                ];
                const deduplicatedPartners = partners.filter((partner) => selectedPartners.some((it) => it.id === partner.id && it.type === partner.type));

                const newPreviewConfig = new CmpConfig(cmpConfig);
                newPreviewConfig.settings.vendorList = new CmpConfigVendorList({
                    ...newPreviewConfig.settings.vendorList,
                    [CmpConfigVendorListField.VENDORS]: deduplicatedPartners.filter((it) => it.isIABVendor).map((it) => it.id),
                    [CmpConfigVendorListField.GOOGLE_PROVIDERS]: deduplicatedPartners.filter((it) => it.isGoogleProvider).map((it) => it.id),
                    [CmpConfigVendorListField.SIRDATA_VENDORS]: deduplicatedPartners.filter((it) => it.isSirdataVendor).map((it) => it.id)
                });
                setPreviewConfig(newPreviewConfig);
            } catch (e) {
                setPreviewConfig(cmpConfig);
            }
        })();
    }, [cmpConfig]);

    return (
        <ContentBlock>
            <FlexContent
                direction={FlexContentDirection.ROW}
                spacing={FlexContentSpacing.LARGE}
                justify={FlexContentJustify.SPACE_EVENLY}
                cssClass={"purposes-display-section"}
            >
                {CMP_CONFIG_DISPLAY_MODES.map((mode) =>
                    <div key={mode} className="purposes-display-section__item">
                        <InputRadio
                            id={`display-mode-${mode.toLowerCase()}`}
                            value={mode}
                            checked={mode === cmpConfig.settings.vendorList.displayMode}
                            onChange={() => onChange(mode)}
                        />
                        <label className="label" htmlFor={`display-mode-${mode.toLowerCase()}`}>
                            <img src={`/images/${mode.toLowerCase()}.png`} alt=""/>
                        </label>
                        <div className="purposes-display-section__item__title">
                            <span>{textConfigPurposes(`tab.display.mode.${mode}`)}</span>
                            <IconTooltip
                                icon={{name: "visibility"}}
                                text={t("list.action.preview")}
                                onClick={() => handlePreview(mode)}
                            />
                        </div>
                    </div>
                )}
            </FlexContent>
            <Paragraph alignment={ParagraphAlignment.JUSTIFY} withSpacing>
                <span dangerouslySetInnerHTML={{__html: textConfigPurposes("tab.display.description")}}/>
            </Paragraph>
            <ModalNew
                size={ModalNewSize.MAX_WIDTH}
                active={!!activePreviewDisplayMode}
                onClose={() => setActivePreviewDisplayMode(undefined)}
            >
                <ModalContent>
                    <ModalTitle>{textConfigPurposes(`tab.display.preview.${activePreviewDisplayMode}`)}</ModalTitle>
                    <Loadable loading={isLoadingPreview}>
                        <Preview cmpConfig={previewConfig}/>
                    </Loadable>
                </ModalContent>
            </ModalNew>
        </ContentBlock>
    );
};

export default PurposesDisplay;
