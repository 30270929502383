import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {escapeHtml, unescapeHtml} from "../../utils/string";
import {CmpConfigLanguage} from "../../api/model/cmp/config/CmpConfigLanguage";
import {CmpConfigText} from "../../api/model/cmp/config/CmpConfigText";
import ModalTranslationLayout from "./ModalTranslationLayout";
import {TranslationPortalFile} from "../../utils/constants";
import {Box, BoxRadius, BoxSpacing, FieldBlock, FormLayoutRows, InputText, Textarea} from "@sirdata/ui-lib";
import {CmpConfigField} from "../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettingsField} from "../../api/model/cmp/config/CmpConfigSettingsField";

type ModalTranslationTextsProps = {
    active: boolean;
    initValue: Map<string, CmpConfigText> | undefined;
    onClose: () => void;
    onSubmit: (texts: Map<string, CmpConfigText>) => void;
};

const ModalTranslationTexts: FunctionComponent<ModalTranslationTextsProps> = ({active, initValue, onClose, onSubmit}) => {
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const [texts, setTexts] = useState<Map<string, CmpConfigText>>(new Map(initValue));
    const [currentLanguage, setCurrentLanguage] = useState<CmpConfigLanguage>(CmpConfigLanguage.ENGLISH);
    const [currentLanguageTexts, setCurrentLanguageTexts] = useState<CmpConfigText>(new CmpConfigText());
    const [definedLanguages, setDefinedLanguages] = useState<string[]>([]);

    const handleSelectLanguage = (language: CmpConfigLanguage) => {
        const newTexts = new Map(texts);
        const languageTexts = new CmpConfigText({...newTexts.get(language.name)});
        if (languageTexts[CmpConfigText.MAIN_TITLE]) {
            languageTexts[CmpConfigText.MAIN_TITLE] = unescapeHtml(languageTexts[CmpConfigText.MAIN_TITLE]);
        }
        if (languageTexts[CmpConfigText.MAIN_CUSTOM_DESCRIPTION]) {
            languageTexts[CmpConfigText.MAIN_CUSTOM_DESCRIPTION] = unescapeHtml(languageTexts[CmpConfigText.MAIN_CUSTOM_DESCRIPTION]);
        }
        newTexts.set(language.name, languageTexts);
        setCurrentLanguage(language);
        setCurrentLanguageTexts(languageTexts);
        setTexts(newTexts);
    };

    const handleChangeTexts = (field: string, value: string) => {
        const newCmpConfigText = new CmpConfigText({...currentLanguageTexts, [field]: value});
        const newTexts = new Map(texts);
        newTexts.set(currentLanguage.name, newCmpConfigText);
        setCurrentLanguageTexts(newCmpConfigText);
        setTexts(newTexts);
    };

    const handleSubmit = () => {
        const newTexts = new Map<string, CmpConfigText>();
        texts.forEach((item, language) => {
            if (item[CmpConfigText.MAIN_TITLE] || item[CmpConfigText.MAIN_CUSTOM_DESCRIPTION]) {
                const newCmpConfigText = new CmpConfigText();
                newCmpConfigText[CmpConfigText.MAIN_TITLE] = escapeHtml(item[CmpConfigText.MAIN_TITLE] || "");
                newCmpConfigText[CmpConfigText.MAIN_CUSTOM_DESCRIPTION] = item[CmpConfigText.MAIN_CUSTOM_DESCRIPTION] || "";
                newTexts.set(language, newCmpConfigText);
            }
        });
        onSubmit(newTexts);
    };

    useEffect(() => {
        const definedLanguages: string[] = [];
        texts.forEach((item, language) => {
            if (item[CmpConfigText.MAIN_TITLE] || item[CmpConfigText.MAIN_CUSTOM_DESCRIPTION]) {
                definedLanguages.push(language);
            }
        });
        setDefinedLanguages(definedLanguages);
    }, [currentLanguage, texts]);

    useEffect(() => {
        if (active && initValue) {
            setTexts(new Map(initValue));
            setCurrentLanguage(CmpConfigLanguage.ENGLISH);

            const defaultLanguageTexts = initValue?.get(CmpConfigLanguage.ENGLISH.name);
            if (defaultLanguageTexts) {
                setCurrentLanguageTexts(new CmpConfigText({
                    [CmpConfigText.MAIN_TITLE]: unescapeHtml(defaultLanguageTexts[CmpConfigText.MAIN_TITLE] || ""),
                    [CmpConfigText.MAIN_CUSTOM_DESCRIPTION]: unescapeHtml(defaultLanguageTexts[CmpConfigText.MAIN_CUSTOM_DESCRIPTION] || "")
                }));
            } else {
                setCurrentLanguageTexts(new CmpConfigText());
            }
        }
    }, [active, initValue]);

    return (
        <ModalTranslationLayout
            active={active}
            titleKey={"modal_translation.set_texts"}
            descriptionKey={"modal_translation.set_texts_desc"}
            currentLanguage={currentLanguage}
            definedLanguages={definedLanguages}
            onSelectLanguage={handleSelectLanguage}
            onClose={onClose}
            onSubmit={handleSubmit}
        >
            <Box spacing={BoxSpacing.MEDIUM} radius={BoxRadius.MD}>
                <FormLayoutRows>
                    <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.TEXTS}.${CmpConfigText.MAIN_TITLE}`)}>
                        <InputText
                            value={currentLanguageTexts[CmpConfigText.MAIN_TITLE] || ""}
                            onChange={(value) => handleChangeTexts(CmpConfigText.MAIN_TITLE, value)}
                        />
                    </FieldBlock>
                    <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.TEXTS}.${CmpConfigText.MAIN_CUSTOM_DESCRIPTION}`)}>
                        <Textarea
                            value={currentLanguageTexts[CmpConfigText.MAIN_CUSTOM_DESCRIPTION] || ""}
                            onChange={(value) => handleChangeTexts(CmpConfigText.MAIN_CUSTOM_DESCRIPTION, value)}
                            rows={10}
                        />
                    </FieldBlock>
                </FormLayoutRows>
            </Box>
        </ModalTranslationLayout>
    );
};

export default ModalTranslationTexts;
